.row {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.input-error {
  margin-left: 10px;
  /* color: $errorColor; */
}

.btn {
  margin-left: 10px;
  padding: 0 10px;
  position: relative;
  display: flex;
  color: #fff;
  /* background: $topbarBgColor; */
  height: 30px;
  border-radius: 5px;
  font-size: 1rem;
  border: 1px solid #cccccc;
  cursor: pointer;
}

.btn:hover {
  box-shadow: 0px 0px 2px rgba(68, 68, 68, 0.6);
}

.btn:active {
  transform: translateY(3px);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin: 10px;
}

.label {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 5px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.8rem;
  padding-right: 30px;
  padding-top: 12px;
}

.selector-label {
  position: absolute;
  top: 20px;
  right: 20px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.8rem;
}

.input-group-addon {
  display: inline-flex;
  min-width: 2em;
  border-width: 1px;
  border-style: solid;
  border-color: #a6a6a6;
  background-color: #eaeaea;
  color: #848484;
  padding: 0.429em;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  align-items: center;
}

.input-group-addon-right {
  display: inline-flex;
  width: 3em;
  border-width: 1px;
  border-style: solid;
  border-color: #a6a6a6;
  background-color: #eaeaea;
  color: #848484;
  padding: 0.429em;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  align-items: center;
  font-size: 10px;
}

.input-error-control {
  border: 1px solid !important;
}

.input-control {
  display: block;
  flex: 1;
  width: 1%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-left: 0;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cropHeaderContainer {
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  padding-left: 30px;
}

.cropBackContainer {
  display: flex;
  /* flex: 0.5; */
  height: 55px;
  align-items: center;
  justify-content: flex-start;
  width: 30px;
}

.cropTitleContainer {
  display: flex;
  flex: 5;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}

.cropTitleContainer1 {
  display: flex;
  flex: 3;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}

.cropTitleContainer2 {
  display: flex;
  flex: 4;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}

.cropLanguageContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
}

.circleBackground {
  border-radius: 50%;
  background-color: #4ab575;
  width: 23px;
  height: 23px;
  position: relative;
}

.plusImage {
  position: absolute;
  top: 23%;
  left: 18%;
}

.headerText {
  padding-left: 15px;
  color: #20323F;
  cursor: pointer;
}

.headerImage {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #ccc;
}

.headerLanguage {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer !important;
}

.headerLanguageTop {
  display: flex;
  flex: 9;
  cursor: pointer !important;
}

.headerLanguageBottom {
  display: flex;
  height: 5px;
}

.activeText {
  background-color: #4ab575;
}

.breadcrumbContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* padding-left: 4%; */
  padding-right: 4%;
  position: fixed;
  background-color: #FAFAFA;
  z-index: 10000;
}

.breadcrumb {
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  margin-right: 20px;
}

.breadcrumbText {
  display: flex;
  /* flex: 5; */
  align-items: center;
  justify-content: flex-start;
  color: #20323F;
  padding-top: 10px;
}

.breadcrumbSelected {
  display: flex;
  height: 5px;
}

.breadCrumbActive {
  border-bottom: 5px solid #4ab575;
  padding-bottom: 10px;
}

.saveContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding-right: 20px;
}

.errorText {
  color: red;
  font-family: Asap;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.TextBoxContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-bottom: 20px;
}

.TextBoxContainerWithoutPadding {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
}

.leftLabelContainer {
  display: flex;
  flex: 2;
}

.leftLabelContainer2 {
  display: flex;
  flex: 2;
}

.leftLabelContainer3 {
  display: flex;
  flex: 5;
}

.leftSelectLabelContainer {
  display: flex;
  flex: 4;
}

.leftSelectLabelContainer4 {
  display: flex;
  flex: 1.5;
}

.labelText {
  font-family: Asap;
  color: #172630;
  font-size: 16px;
  line-height: 24px;
}

.rightLabelContainer {
  display: flex;
  flex-direction: row;
  flex: 8;
  align-items: flex-start;
  /* justify-content: space-around; */
}

.rightSelectLabelContainer {
  display: flex;
  flex-direction: row;
  flex: 6;
  align-items: flex-start;
  /* justify-content: space-around; */
}

.rightTextAreaLabelContainer {
  display: flex;
  flex-direction: column;
  flex: 8;
  align-items: left;
  justify-content: space-around;
  text-align: justify;
}

.selectInput {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 20px;
  width: 167px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
  font-size: 12px;
  background-color: #ffffff;
}

.textAreaInput {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: Asap;
  font-size: 16px;
  width: 98%;
  padding: 10px;
}

.textBoxInput {
  height: 25px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 5px;
  font-size: 12px;
  font-family: sans-serif;
  width: 300px;
}

.custom-file-input {
  /* background-color: rgba(0,0,0,0.2); */
}

.textBoxInputSorting {
  height: 25px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 5px;
  font-size: 12px;
  width: 20px !important;
  font-family: sans-serif;
}

.selectBox {
  height: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

/* The checkBoxContainer */
.checkBoxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkBoxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkboxCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-left: 5px;
}

/* On mouse-over, add a grey background color */
.checkBoxContainer:hover input~.checkboxCheckmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkBoxContainer input:checked~.checkboxCheckmark {
  /* background-color: rgba(0,0,0,0.2); */
}

/* Create the checkboxCheckmark/indicator (hidden when not checked) */
.checkboxCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkboxCheckmark when checked */
.checkBoxContainer input:checked~.checkboxCheckmark:after {
  display: block;
}

/* Style the checkboxCheckmark/indicator */
.checkBoxContainer .checkboxCheckmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #4ab575;
}

input:focus+.slider {
  box-shadow: 0 0 1px #4ab575;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.on {
  display: none;
}

.off {
  color: #000;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 70%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

.on {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 35%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked+.slider .on {
  display: block;
}

input:checked+.slider .off {
  display: none;
}


.radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioCheckmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.4);
}

/* On mouse-over, add a grey background color */
.radio:hover input~.radioCheckmark {
  /* background-color: #ccc; */
}

/* When the radio button is checked, add a blue background */
.radio input:checked~.radioCheckmark {
  background-color: #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked~.radioCheckmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio .radioCheckmark:after {
  top: 2.8px;
  left: 3px;
  width: 9px;
  height: 10px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
}

td,
th {
  border: 1px solid #dddddd;
  /* text-align: center; */
  padding: 8px;
}

th {
  text-align: center;
  background: #F8F8F8;
  border: 1px solid #E0E0E0;
  border-radius: 0px;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  background: white;
  outline: none;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  box-shadow: 0 0 34px 0 rgba(0, 0, 0, 0.24);
  overflow-y: auto;
  position: relative;
  padding-top: 0px !important;
}

.disabledText {
  width: 300px;
  margin-Right: 10px;
}

.smallText {
  width: 50px;
  margin-right: 10px;
}

.nameText {
  width: 400px;
  margin-Right: 10px;
}

.fertilizerMix {
  width: 260px;
  margin-Right: 10px;
}

.latLng {
  width: 100px;
  margin-right: 10px;
  height: 30px;
}

.spinner {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #4ab575;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  background-color: transparent;
  align-self: center;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
}

.loader {
  margin: 100px auto;
  font-size: 15px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load5 {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #4ab575,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #4ab575, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #4ab575,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #4ab575,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #4ab575,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #4ab575,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #4ab575,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #4ab575;
  }
}

@keyframes load5 {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #4ab575,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #4ab575, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #4ab575,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #4ab575,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #4ab575,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #4ab575,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #4ab575,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #4ab575;
  }
}

@media only screen and (max-width: 1200px) {
  .leftLabelContainer {
    display: flex;
    flex: 4;
  }

  .leftLabelContainer2 {
    display: flex;
    flex: 4;
  }

  .rightLabelContainer {
    display: flex;
    flex-direction: row;
    flex: 6;
    align-items: flex-start;
  }

  .leftLabelContainer3 {
    display: flex;
    flex: 4;
  }
  .rightTextAreaLabelContainer {
    display: flex;
    flex-direction: column;
    flex: 6;
    align-items: left;
    justify-content: space-around;
    text-align: justify;
  }

  .cropTitleContainer {
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
  }
  .cropLanguageContainer {
    display: flex;
    flex-direction: row;
    flex: 3;
    align-items: center;
    justify-content: space-evenly;
  }
}